.navigation {
  height: $nav-height;
  background: $dark-highlight;

  // Title on navigation bar
  .title {
    position: absolute;
    padding-left: 20px;
    float: left;
    line-height: $nav-height;
    text-transform: uppercase;
    font-size: 1.4em;
    color: $light-highlight;
  }

  // Container with no padding for navbar
  .nav-container {
    max-width: $content-width;
    margin: 0 auto;
  }

  // Navigation 
  nav {
    float: right;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        float: left;
        position: relative;
        a {
          display: block;
          padding: 0 20px;
          line-height: $nav-height;
          background: $dark-highlight;
          color: $light-highlight;
          text-decoration: none;
          &:hover {
            background: $link-hover-color;
          }
          &.active {
            background: $light-highlight;
            color: $link-hover-color;
          }
        } 
      }
    }
  }

}

// Mobile navigation 
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: $dark-highlight;
  height: $nav-height;
  width: $nav-height;
}

@media only screen and (max-width: 798px) {
  // Hamburger nav visible on mobile only
  .nav-mobile {
    display: block;
  }

  nav {
   width: 100%;
    padding: $nav-height 0 15px;
    ul {
      display: none;
      
      li {
        float: none;
        a {
          padding: 15px;
          line-height: 20px;
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
    &.active {
      ul {
        display: block;
      }
    }
  }
}

@media screen and (min-width: $breakpoint) {
  .nav-list {
    display: block !important;
  }
}

// Toggle for displaying link list
#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: $light-highlight;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
  }
  span:after {
    bottom: -10px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
